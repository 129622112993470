import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "chat" */ '@/views/index.vue'),
    redirect: 'chat',
    children: [
      {
        path: '/chat',
        name: 'chat',
        component: () =>
          import(/* webpackChunkName: "chat" */ '@/views/Chat.vue')
      },
      {
        path: '/order',
        name: 'order',
        component: () =>
          import(/* webpackChunkName: "order" */ '@/views/Order.vue')
      },
      {
        path: '/draw',
        name: 'draw',
        component: () =>
          import(/* webpackChunkName: "draw" */ '@/views/Draw.vue')
      }
    ]
  },
  {
    path: '/register/:code',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: "chat" */ '@/views/Register.vue')
  },
  {
    path: '/register', // 这是没有 code 参数的情况
    component: () =>
      import(/* webpackChunkName: "chat" */ '@/views/Register.vue')
  },
  {
    path: '/mregister',
    name: 'mregister',
    component: () =>
      import(/* webpackChunkName: "chat" */ '@/views/MRegister.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () =>
      import(/* webpackChunkName: "chat" */ '@/views/MRegisterSuccess.vue')
  },
  {
    path: '/forgetPsd',
    name: 'forgetPsd',
    component: () =>
      import(/* webpackChunkName: "chat" */ '@/views/ForgetPsd.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () =>
      import(/* webpackChunkName: "chat" */ '@/views/Agreement.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () =>
      import(/* webpackChunkName: "chat" */ '@/views/Privacy.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
