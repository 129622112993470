// 会话列表
import {reactive} from 'vue'

const state = reactive({
  // 绘图显示
  drawExpert: false,
  // 图生图弹窗
  graphMask: false,
  // 主体对象图
  mainUrl: '',
  // 合成背景图
  craftUrl: ''
})

const getters = {}

const actions = {}

const mutations = {
  setDrawExpert(state, data) {
    state.drawExpert = data
  },
  setGraphMask(state, data){
    state.graphMask = data
  },
  setMainUrl(state, data){
    state.mainUrl = data
  },
  setCraftUrl(state, data){
    state.craftUrl = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
