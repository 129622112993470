import request from './Axios'

// 生成预付款订单，返回二维码url
export const orderadd = data => {
  return request({
    url: '/order/add',
    method: 'post',
    data
  })
}

// 获取产品列表
export const getproductinfo = params => {
  return request({
    url: `/product/getproductinfo/${params.type}/0`,
    method: 'get'
  })
}

// 获取每个产品
export const getproductinfobycode = params => {
  return request({
    url: `/product/getproductinfobycode/${params.code}`,
    method: 'get'
  })
}

// 获取订单列表
export const getorderlist = params => {
  return request({
    url: `/order/getlistwithlimit/0/${params.size}/${params.current}`,
    method: 'get'
  })
}

// 获取微信支付订单状态
export const getwechatorderstatus = params => {
  return request({
    url: `/order/getwechatorderstatus/${params.orderId}`,
    method: 'get'
  })
}

// 关闭订单
export const closeorder = params => {
  return request({
    url: `/order/close/${params.orderId}`,
    method: 'get'
  })
}

// 查询订单详情
export const ordergetlist = params => {
  return request({
    url: `/order/getlist/${params.orderId}`,
    method: 'get'
  })
}

// 黄钻根据金额查询token
export const orangeMoney = params => {
  return request({
    url: `/product/gettokenbyprice/orange/${params.money}`,
    method: 'get'
  })
}

// 紫钻根据金额查询token
export const purpleMoney = params => {
  return request({
    url: `/product/getpaperbyprice/purple/${params.money}`,
    method: 'get'
  })
}

// 个人中心信息
export const getpersonaldata = () => {
  return request({
    url: `/login/getpersonaldata`,
    method: 'get'
  })
}

// 单独获得不同身份剩余权益接口
export const getpersonalused = () => {
  return request({
    url: `/login/getpersonalused`,
    method: 'get'
  })
}

// 单独获得不同身份剩余权益接口
export const getformula = (params) => {
  return request({
    url: `/product/getformula/${params.type}`,
    method: 'get'
  })
}