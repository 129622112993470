import request from './Axios'

// 获取分类
export const getcategory = () => {
  return request({
    url: '/categoryandprompts/getcategory/0',
    method: 'get'
  })
}
// 获取分类提示词
export const getpromptsbycategory = data => {
  return request({
    url: `/categoryandprompts/getpromptsbycategory/${data.labelswitch}/${data.id}`,
    method: 'get'
  })
}

// 收藏
export const addpromptsfavorites = data => {
  return request({
    url: `/categoryandprompts/addpromptsfavorites/${data.id}`,
    method: 'get'
  })
}

// 取消收藏
export const droppromptsfavorites = data => {
  return request({
    url: `/categoryandprompts/droppromptsfavorites/${data.id}`,
    method: 'get'
  })
}

// 查询请求配置
export const getrequestconfig = params => {
  return request({
    url: `/requestdata/getrequestconfig/${params.id}`,
    method: 'get'
  })
}

// 保存或修改提问配置
export const updaterequestconfig = data => {
  return request({
    url: `/requestdata/updaterequestconfig`,
    method: 'post',
    data
  })
}

// 绘画

// 获取提示词分类
export const getdrawcategory = () => {
  return request({
    url: `/categoryandprompts/getdrawcategory/0`,
    method: 'get'
  })
}

// 收藏
export const adddrawpromptsfavorites = data => {
  return request({
    url: `/categoryandprompts/adddrawpromptsfavorites/${data.id}`,
    method: 'get'
  })
}

// 取消收藏
export const dropdrawpromptsfavorites = data => {
  return request({
    url: `/categoryandprompts/dropdrawpromptsfavorites/${data.id}`,
    method: 'get'
  })
}


// 绘图
export const getdrawpromptsbycategory = data => {
  return request({
    url: `/categoryandprompts/getdrawpromptsbycategory/${data.id}`,
    method: 'get'
  })
}


// 上传主体图PNG
export const drawdataUploadpngimg = data => {
  return request({
    url: `/drawdata/uploadpngimg`,
    method: 'post',
    data
  })
}

// 上传合成背景图
export const drawdataUploadimg = data => {
  return request({
    url: `/drawdata/uploadimg`,
    method: 'post',
    data
  })
}