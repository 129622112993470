// 公共状态值
import {reactive} from 'vue'
import {ElMessage} from 'element-plus'
import {getpersonaldata, getpersonalused, getwechatorderstatus, orderadd} from '@/api/order'
import {clearStorage} from '@/utils/common'
import {convertTimestampToDate} from '@/utils/validate'
import router from '@/router'

const state = reactive({
  // 登录弹框
  loginMask: false,
  // 登陆框免费使用提示
  freeTips: false,
  // 用户名
  userName: '',
  // 会话提示词
  expertMask: false,
  // 描述弹框
  descDialog: false,
  // 发送会话状态
  loading: false,
  // vip弹框
  vipsMask: false,
  // vip默认展示
  vipType: 'blue',
  // 联系客服
  serviceMask: false,
  // 联系客服内容
  serviceContent: false,
  // 头部标题展示
  routeDefault: true,
  // 二维码弹窗
  orderCode: false,
  // 订单详情弹窗
  orderDialogVisible: false,
  // 微信支付二维码
  orderCodeUrl: '',
  // 二维码失效时间
  orderCodeTime: 0,
  // vip购买订单查询定时器
  vipTimer: null,
  // 点击开启回话新增
  clickTrue: false,
  // 点击切换存id
  promptsIdChange: 0,
  // 关于我们弹窗
  aboutMask: false,
  // 是否超时
  timeoOut: false,
  // 会话设置弹框
  sessionMask: false,
  // token用量
  useToken: {},
  // token用量高度
  useTokenHeight: 0,
  // 输入框提示
  placeholder:
    '请直接在此输入对话内容。您也可以点击左侧开启新聊天，通过我们为您提供的提示词分类辅助进行对话',
  drawplaceholder:
    '请直接在此描绘你心中画面的样子，尽量详细，可以使用一些风格修饰词。您也可以点击“使用提示词”分类铺助进行绘制',
  // 模型类型
  modelCode: '',
  // 当前接口返回模型类型
  currentModel: '',
  // 钻石
  diamond: [],
  // 个人中心
  userInfoMask: false,
  // 分享
  shareMask: false,
  // 会话提示信息
  tipsMask: true,
  // 插入提示语
  insertBtn: false,
  // 签到弹窗
  signMask: false,
  // 使用帮助弹窗
  helpMask: false,
  // 签到天数
  signTotal: 0,
  // 签到tokens
  signTokens: 0,
  // 绘画图片
  picActive: '<auto>',
  // 绘画数量
  picNum: 1,
  // 绘画尺寸
  sizeActive: '1024*1024',
  // 绘画尺寸存储
  sizeActiveLoad: '1024*1024',
  // 自定义比例
  proportion: '',
  // 绘图聊天
  drawTrue: false,
  // 会话切换监听
  chatChange: false,
  // 绘图历史记录分页
  drawHistoryPage: 1,
  // 绘图张数
  remainingPaper: 0,
  // sse状态
  sseState: false,
  // mj历史记录对话
  mjHistoryList: [],
  // 历史记录对话存储
  historyListArr: [],
  // sse返回error
  sseError: false,
  // 登陆存储历史记录
  historyLogin: [],
  // 登陆存储历史记录数量
  historyLoginTotal: 0,
  // 会话打字效果状态
  sureSendWayState: false,
  // 咒语书弹窗状态
  spellBookState: false,
  // 咒语书id集合
  spellIdsIds: [],
  // 咒语书选中集合
  promptArrId: []
})

const getters = {
  sseStates(state) {
    return state.sseState
  },
  sseErrors(state) {
    return state.sseError
  },
  modelCode() {
    return state.modelCode
  }
}

const actions = {
  orderCreate({dispatch, commit, state}, {item, price}) {
    if (!localStorage.getItem('token')) {
      ElMessage.warning('请登录购买VIP套餐')
      setTimeout(() => {
        commit('setVipsMask', false)
        commit('setLoginMask', true)
      }, 3000)
      return false
    }
    let data = {
      productId: item.id,
      orderStatus: 0,
      payMethod: 1,
      diamond: state.vipType
    }
    if (state.vipType == 'orange' || state.vipType == 'purple') {
      data.price = price
    }
    orderadd(data).then(response => {
      const {data: res, status} = response
      if (status == 200) {
        if (res.code == 0) {
          commit('setOrderCodeTime', res.data.codeExpiratedTime)
          commit('setOrderCodeUrl', res.data.codeUrl)
          commit('setOrderCode', true)
          const timer = setInterval(() => {
            getwechatorderstatus({orderId: res.data.orderId})
              .then(response => {
                const {data: res, status} = response
                if (status == 200) {
                  if (res.code == 0 && res.data[0].orderStatus == 2) {
                    ElMessage.success('支付成功')
                    commit('setOrderCode', false)
                    commit('setOrderDialogVisible', false)
                    commit('setVipsMask', false)
                    clearInterval(state.vipTimer)
                    commit('setVipTimer', null)
                    router.push('/order')
                    dispatch('personal')
                    dispatch('getpersonalusedlist')
                  }
                }
                if (status == 202) {
                  if (res.code == 0) {
                    clearInterval(state.vipTimer)
                    commit('setVipTimer', null)
                    ElMessage.warning(res.msg)
                  }
                }
              })
              .catch(() => {
                clearInterval(state.vipTimer)
                commit('setVipTimer', null)
              })
          }, 2000)
          commit('setVipTimer', timer)
        }
      }
      if (status == 202) {
        if (res.code == 0) {
          ElMessage.warning(res.msg)
        }
        if (res.code == 1) {
          ElMessage.warning('验证失败，请重新登录或完成注册')
          clearStorage()
          setTimeout(() => {
            commit('setLoginMask', true)
          }, 3000)
        }
        if (res.code == 2) {
          ElMessage.warning(res.msg)
          commit('setVipsMask', false)
          res.data.createdAt = convertTimestampToDate(res.data.createdAt)
          res.data.takingEffectTime = convertTimestampToDate(
            res.data.takingEffectTime
          )
          res.data.expiratedTime = convertTimestampToDate(
            res.data.expiratedTime
          )
          setTimeout(() => {
            localStorage.setItem('orderlist', JSON.stringify(res.data))
            router.push({
              path: '/order',
              query: {
                a: router.currentRoute.value.path == '/order' ? 1 : 2
              }
            })
          }, 3000)
        }
      }
    })
  },
  personal({commit}) {
    getpersonaldata().then(response => {
      const {data: res, status} = response
      if (status == 200) {
        if (res.code == 0) {
          localStorage.setItem(
            'diamond',
            JSON.stringify(res.data.basicInfo.diamond)
          )
          commit('setDiamond', res.data.basicInfo.diamond)
        }
      }
    })
  },
  getpersonalusedlist ({commit}){
    getpersonalused().then(({data: res}) => {
      if (res.code === 0) {
        if (res.data.orderData) {
          localStorage.setItem('useToken', JSON.stringify(res.data.orderData))
          this.commit('common/setUseToken', res.data.orderData)
        }
        this.commit('common/setRemainingPaper', res.data.remainingPaper)
        if (res.data.diamond) {
          localStorage.setItem('diamond', JSON.stringify(res.data.diamond))
          commit('setDiamond', res.data.diamond)
        }
        if (res.data.remainingQuestioningTimes) {
          this.commit('common/setPlaceholder', `会员 | 剩余提问次数：${res.data.remainingQuestioningTimes || 0}`)
        } else {
          this.commit('common/setPlaceholder', `请直接在此输入对话内容。您也可以点击左侧开启新聊天，通过我们为您提供的提示词分类辅助进行对话`)
        }
        if (res.data.diamond.indexOf('purple') !== -1) {
          if (res.data.remainingPaper) {
            if (res.data.remainingPaper === '无限') {
              this.commit('common/setDrawPlaceholder', `请直接在此描绘你心中画面的样子，尽量详细，可以使用一些风格修饰词。您也可以点击“使用提示词”分类铺助进行绘制。`)
            } else {
              this.commit('common/setDrawPlaceholder', `会员 | 剩余绘图张数：${res.data.remainingPaper || 0}`)
            }
          } else {
            this.commit('common/setDrawPlaceholder', `请直接在此描绘你心中画面的样子，尽量详细，可以使用一些风格修饰词。您也可以点击“使用提示词”分类铺助进行绘制。`)
          }
        } else {
          this.commit('common/setDrawPlaceholder', `会员 | 剩余绘图张数：${res.data.remainingPaper || 0}`)
        }
        this.commit('common/setRemainingPaper', res.data.remainingPaper || 0)
      }
    })
  }
}

const mutations = {
  setLoginMask(state, data) {
    state.loginMask = data
  },
  setUserName(state, data) {
    state.userName = data
  },
  setExpertMask(state, data) {
    state.expertMask = data
  },
  setLoading(state, data) {
    state.loading = data
  },
  setVipsMask(state, data) {
    state.vipsMask = data
  },
  setRouteDefault(state, data) {
    state.routeDefault = data
  },
  setServiceContent(state, data) {
    state.serviceContent = data
  },
  setServiceMask(state, data) {
    state.serviceMask = data
  },
  setOrderCode(state, data) {
    state.orderCode = data
  },
  setOrderDialogVisible(state, data) {
    state.orderDialogVisible = data
  },
  setOrderCodeUrl(state, data) {
    state.orderCodeUrl = data
  },
  setOrderCodeTime(state, data) {
    state.orderCodeTime = data
  },
  setVipTimer(state, data) {
    state.vipTimer = data
  },
  setClickTrue(state, data) {
    state.clickTrue = data
  },
  setPromptsIdChange(state, data) {
    state.promptsIdChange = data
  },
  setAboutMask(state, data) {
    state.aboutMask = data
  },
  setTimeoOut(state, data) {
    state.timeoOut = data
  },
  setFreeTips(state, data) {
    state.freeTips = data
  },
  setUseToken(state, data) {
    state.useToken = data
  },
  setUseTokenHeight(state, data) {
    state.useTokenHeight = data
  },
  setPlaceholder(state, data) {
    state.placeholder = data
  },
  setDrawPlaceholder(state, data) {
    state.drawplaceholder = data
  },
  setSessionMask(state, data) {
    state.sessionMask = data
  },
  setModelCode(state, data) {
    state.modelCode = data
  },
  setCurrentModel(state, data) {
    state.currentModel = data
  },
  setVipType(state, data) {
    state.vipType = data
  },
  setDiamond(state, data) {
    state.diamond = data
  },
  setUserInfoMask(state, data) {
    state.userInfoMask = data
  },
  setShareMask(state, data) {
    state.shareMask = data
  },
  setTipsMask(state, data) {
    state.tipsMask = data
  },
  setInsertBtn(state, data) {
    state.insertBtn = data
  },
  setSignMask(state, data) {
    state.signMask = data
  },
  setHelpMask(state, data) {
    state.helpMask = data
  },
  setSignTotal(state, data) {
    state.signTotal = data
  },
  setSignTokens(state, data) {
    state.signTokens = data
  },
  setPicActive(state, data) {
    state.picActive = data
  },
  setPicNum(state, data) {
    state.picNum = data
  },
  setSizeActive(state, data) {
    state.sizeActive = data
  },
  setSizeActiveLoad(state, data) {
    state.sizeActiveLoad = data
  },
  setProportion(state, data) {
    state.proportion = data
  },
  setDrawTrue(state, data) {
    state.drawTrue = data
  },
  setChatChange(state, data) {
    state.chatChange = data
  },
  setDrawHistoryPage(state, data) {
    state.drawHistoryPage = data
  },
  setRemainingPaper(state, data) {
    state.remainingPaper = data
  },
  setSseState(state, data) {
    state.sseState = data
  },
  setMjHistoryList(state, data) {
    state.mjHistoryList = data
  },
  setHistoryListArr(state, data) {
    state.historyListArr = data
  },
  setSseError(state, data) {
    state.sseError = data
  },
  setHistoryLogin(state, data) {
    state.historyLogin = data
  },
  setHistoryLoginTotal(state, data) {
    state.historyLoginTotal = data
  },
  setSureSendWayState(state, data) {
    state.sureSendWayState = data
  },
  setSpellBookState(state, data) {
    state.spellBookState = data
  },
  setSpellIdsIds(state, data) {
    state.spellIdsIds = data
  },
  setPromptArrId(state, data) {
    state.promptArrId = data
  },

}

export default {
  state,
  getters,
  actions,
  mutations
}
