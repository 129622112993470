import Fingerprint2 from 'fingerprintjs2'

export const getWebTouch = () => {
  let options = {
    excludes: {
      userAgent: false,
      language: true,
      colorDepth: true,
      deviceMemory: false,
      pixelRatio: true,
      hardwareConcurrency: false,
      screenResolution: false,
      availableScreenResolution: true,
      timezoneOffset: true,
      timezone: false,
      sessionStorage: true,
      localStorage: true,
      indexedDb: true,
      addBehavior: true,
      openDatabase: true,
      cpuClass: true,
      platform: false,
      doNotTrack: true,
      plugins: true,
      canvas: true,
      webgl: true,
      webglVendorAndRenderer: false,
      adBlock: true,
      hasLiedLanguages: true,
      hasLiedResolution: true,
      hasLiedOs: true,
      hasLiedBrowser: true,
      touchSupport: true,
      fonts: false,
      fontsFlash: true,
      audio: true,
      enumerateDevices: true
    }
  }
  Fingerprint2.get(options, components => {
    const values = components.map((component, index) => {
      if (index === 0) {
        //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
        return component.value.replace(/\bNetType\/\w+\b/, '')
      }
      return component.value
    })
    // 生成最终id murmur
    const murmur = Fingerprint2.x64hash128(values.join(''), 31)
    localStorage.setItem('webTouch', murmur)
  })
}
