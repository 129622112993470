import request from './Axios'

// 发送会话
export const postdatachat = data => {
  return request({
    url: '/requestdata/postdata',
    method: 'post',
    data
  })
}

// 发送对话sse
export const initiatetask = data => {
  return request({
    url: '/requestdata/initiatetask',
    method: 'post',
    data
  })
}

// 发送绘图
export const drawdatapostdata = data => {
  return request({
    url: '/drawdata/postdata',
    method: 'post',
    data
  })
}

// 图生图发送
export const drawdatapostdatabg = data => {
  return request({
    url: '/drawdata/postdatabg',
    method: 'post',
    data
  })
}
// 图生图获取
export const getwxbgdata = params => {
  return request({
    url: `/drawdata/getwxbgdata/${params.chatId}/${params.taskId}/${params.triggerId}`,
    method: 'get'
  })
}

// 会话列表
export const getChatList = params => {
  return request({
    url: `/chat/getlist/${params.id}/${params.limit || 0}/${params.page || 0}`,
    method: 'get'
  })
}

// 新增、修改会话名称
export const updateChatName = data => {
  return request({
    url: `/chat/updatechatname`,
    method: 'post',
    data
  })
}

// 删除会话
export const deleteChat = params => {
  return request({
    url: `/chat/deletechat/${params.id}/${params.type}`,
    method: 'get'
  })
}

// 发送验证码
export const sendRegisterMsg = params => {
  return request({
    url: `/register/sendregistermsg/${params.phone}`,
    method: 'get'
  })
}
// 发送验证码
export const sendRegisterMsgNew = params => {
  return request({
    url: `/register/sendregistermsgmoblie/${params.phone}`,
    method: 'get'
  })
}

// 提交手机号、验证码
export const verifyRegisterMsg = params => {
  return request({
    url: `/register/verifyregistermsg/${params.phone}/${params.code}`,
    method: 'get'
  })
}

// 提交手机号、验证码
export const verifyRegisterMsgNew = params => {
  return request({
    url: `/register/verifyregistermsgmoblie/${params.phone}/${params.code}`,
    method: 'get'
  })
}

// 注册成功
export const saveUserInfo = data => {
  return request({
    url: '/register/saveuserinfo',
    method: 'post',
    data
  })
}
// 注册成功手机
export const saveUserInfoNew = data => {
  return request({
    url: '/register/saveuserinfomoblie',
    method: 'post',
    data
  })
}

// 登录
export const loginToken = data => {
  return request({
    url: '/login/gettoken',
    method: 'post',
    data
  })
}

// 登出
export const loginOut = () => {
  return request({
    url: '/login/logout',
    method: 'get'
  })
}

// 忘记密码发送验证码
export const sendForgetMsg = params => {
  return request({
    url: `/register/sendforgetmsg/${params.phone}`,
    method: 'get'
  })
}

// 忘记密码提交手机号、验证码
export const verifyForgetMsg = params => {
  return request({
    url: `/register/verifyforgetmsg/${params.phone}/${params.code}`,
    method: 'get'
  })
}

// 分享
export const shareMsg = () => {
  return request({
    url: `/share/reg`,
    method: 'get'
  })
}

// 校验邀请码
export const shareVerifyreg = data => {
  return request({
    url: `/share/verifyreg`,
    method: 'post',
    data
  })
}

// 手机号登录发送验证码
export const sendloginmsg = params => {
  return request({
    url: `/login/sendloginmsg/${params.phone}`,
    method: 'get'
  })
}

// 手机号登录
export const verifyloginmsg = params => {
  return request({
    url: `/login/verifyloginmsg/${params.phone}/${params.code}`,
    method: 'get'
  })
}

// 签到
export const getSignin = () => {
  return request({
    url: `/login/signin`,
    method: 'get'
  })
}

// 签到
export const getcumulativesignin = () => {
  return request({
    url: `/login/getcumulativesignin`,
    method: 'get'
  })
}

// 同步会话内容
export const syncchat = params => {
  return request({
    url: `/chat/syncchat/${params.chatId}/${params.type}`,
    method: 'get'
  })
}


// 获取当前任务的历史type
export const historyTypeandusedButton = params => {
  return request({
    url: `/drawdata/gethistorytypeandusedbutton/${params.chatId}/${params.taskId}`,
    method: 'get'
  })
}