import store from '@/store'
export const clearStorage = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('uname')
  localStorage.removeItem('diamond')
  localStorage.removeItem('useToken')
  localStorage.removeItem('placeholder')
  store.commit('common/setUserName', '')
  store.commit('common/setDiamond', [])
  store.commit('common/setUseToken', {})
  store.commit('common/setVipType', 'blue')
  store.commit('common/setModelCode', 'yan')
  store.commit('common/setUseTokenHeight', 0)
}
