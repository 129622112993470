import { createStore } from 'vuex'
let files = require.context('./modules', false, /\.js$/) //获取到moduls下的所有文件

let modules = {}
files.keys().forEach(item => {
  //
  modules[item.replace(/\.\/|\.js/g, '')] = files(item).default
})
// 把所有module下的state，mutions，getters，actions合并到一起,通过添加 namespaced: true 的方式使其成为带命名空间的模块。
Object.keys(modules).forEach(key => {
  modules[key]['namespaced'] = true
})
export default createStore({
  modules
})
