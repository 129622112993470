const bin2hex = s => {
  var i,
    l,
    o = '',
    n
  s += ''
  for (i = 0, l = s.length; i < l; i++) {
    n = s.charCodeAt(i).toString(16)
    o += n.length < 2 ? '0' + n : n
  }
  return o
}
export const getCanvasTouch = domain => {
  var canvas = document.createElement('canvas')
  var ctx = canvas.getContext('2d')
  var txt = domain
  ctx.textBaseline = 'top'
  ctx.font = "14px 'Arial'"
  ctx.textBaseline = 'tencent'
  ctx.fillStyle = '#f60'
  ctx.fillRect(125, 1, 62, 20)
  ctx.fillStyle = '#069'
  ctx.fillText(txt, 2, 15)
  ctx.fillStyle = 'rgba(102, 204, 0, 0.7)'
  ctx.fillText(txt, 4, 17)
  var b64 = canvas.toDataURL().replace('data:image/png;base64,', '')
  // window.atob 用于解码使用 base-64 编码的字符串
  var bin = atob(b64)
  var crc = bin2hex(bin.slice(-16, -12))
  return crc
}
