// 会话列表
import {nextTick, reactive} from 'vue'
import {getChatList, historyTypeandusedButton} from '@/api/common'
import {ElMessage} from 'element-plus'
import {clearStorage} from '@/utils/common'
import {getIndexKey} from '@/utils/validate'
import router from '../../router'

const state = reactive({
  chatId: 0,
  chatValue: '',
  chatListCurrent: 0,
  chatList: {},
  chatObj: {},
  chatKey: '',
  reloadWeb: false,
  reloadWebChat: '',
  modelAndSilde: false,
  buttonType: {},
  scrollHeightFlag: false
})

const getters = {
  chatValues(state) {
    return state.chatValue
  }
}

const actions = {
  // 初始化聊天数据
  getListData({dispatch, commit}, val) {
    let data = {
      id: 0
    }
    let path = router.currentRoute.value.fullPath
    getChatList(data).then(async response => {
      const {data: res, status} = response
      let chatList = {}
      if (status === 200) {
        if (res.code === 0) {
          if (res.msg.chatList.length === 0) {
            if (path === '/draw') {
              this.commit('common/setModelCode', 'sszdraw-v1')
            }
            if (path === '/chat') {
              this.commit('common/setModelCode', 'yan')
            }
            commit('setChatList', {})
            return false
          }
          if (res.msg.chatList) {
            for (const key1 in res.msg.chatList) {
              if (res.msg.chatList.hasOwnProperty.call(res.msg.chatList, key1)) {
                const element = res.msg.chatList[key1]
                chatList[key1] = []
                for (const key in element) {
                  if (element.hasOwnProperty.call(element, key)) {
                    const item = element[key]
                    chatList[key1].push({
                      title: item.chatName,
                      id: item.chatId,
                      edit: false,
                      dialogueShow: false,
                      type: item.type,
                      list: []
                    })
                  }
                }
              }
            }
            if (val === 'expert') {
              chatList['today'][0].dialogueShow = true
            }
            commit('setChatList', chatList)
            let chatListData = [], drawListData = []
            for (const key1 in chatList) {
              if (chatList.hasOwnProperty.call(chatList, key1)) {
                const element = chatList[key1]
                for (const key in element) {
                  if (element.hasOwnProperty.call(element, key)) {
                    const item = element[key]
                    if (item.type === 'chat') {
                      chatListData.push(item.id)
                    }
                    if (item.type === 'draw') {
                      drawListData.push(item.id)
                    }
                  }
                }
              }
            }
            if (path === '/chat') {
              if (chatListData.length > 0) {
                if (!sessionStorage.getItem('slider')) {
                  commit('setChatId', chatListData[0])
                  const {index, key} = getIndexKey(chatList, chatListData[0])
                  commit('setChatListCurrent', index)
                  commit('setChatKey', key)
                }
                dispatch('getListDataChat')
                return false
              } else {
                commit('setChatId', state.chatId)
                commit('setChatListCurrent', state.chatListCurrent)
                commit('setChatKey', state.chatKey)
                this.commit('common/setCurrentModel', 'yan')
                this.commit('common/setModelCode', 'yan')
              }
            }
            if (path === '/draw') {
              if (drawListData.length > 0) {
                if (!sessionStorage.getItem('slider')) {
                  commit('setChatId', drawListData[0])
                  const {index, key} = getIndexKey(chatList, drawListData[0])
                  commit('setChatListCurrent', index)
                  commit('setChatKey', key)
                }
                dispatch('getListDataDraw')
                return false
              } else {
                commit('setChatId', state.chatId)
                commit('setChatListCurrent', state.chatListCurrent)
                commit('setChatKey', state.chatKey)
                this.commit('common/setCurrentModel', 'sszdraw-v1')
                this.commit('common/setModelCode', 'sszdraw-v1')
              }
            }
          }
          commit('setChatList', chatList)
        }
      }
      if (status === 202) {
        if (res.code === 1) {
          ElMessage.warning('验证失败，请重新登录或完成注册')
          commit('setChatValue', '')
          commit('setChatList', [])
          clearStorage()
          setTimeout(() => {
            this.commit('common/setLoginMask', true)
          }, 3000)
        } else {
          ElMessage.warning(res.msg)
        }
      }
    })
  },
  // 对话
  getListDataChat({commit, state, rootGetters}) {
    const data = {
      id: state.chatId
    }
    getChatList(data).then(async response => {
      const {data: res, status} = response
      sessionStorage.removeItem('slider')
      if (status === 200) {
        if (res.code === 0) {
          if (res.msg.firstChatContent) {
            const {model, history} = res.msg.firstChatContent
            this.commit('common/setCurrentModel', model)
            state.chatList[state.chatKey][state.chatListCurrent].list = history
            if (rootGetters['common/modelCode'] === '' || ['mj', 'sszdraw-v1'].includes(rootGetters['common/modelCode'])) {
              this.commit('common/setModelCode', model)
            }
          } else {
            state.chatList[state.chatKey][state.chatListCurrent].list = []
            this.commit('common/setModelCode', 'yan')
            this.commit('common/setCurrentModel', 'yan')
          }
          nextTick(() => {
            document.getElementById('main').scrollTop = document.getElementById('main').scrollHeight
          })
        }
      }
      if (status === 202) {
        if (res.code === 1) {
          ElMessage.warning('验证失败，请重新登录或完成注册')
          commit('setChatValue', '')
          commit('setChatList', [])
          clearStorage()
          setTimeout(() => {
            this.commit('common/setLoginMask', true)
          }, 3000)
        } else {
          ElMessage.warning(res.msg)
        }
      }
    })
  },
  // 绘图
  getListDataDraw({dispatch, commit, state, rootGetters}, val) {
    const data = {
      id: state.chatId,
      limit: 2,
      page: 1
    }
    getChatList(data).then(async response => {
      const {data: res, status} = response
      sessionStorage.removeItem('slider')
      if (status === 200) {
        if (res.code === 0) {
          if (res.msg.firstChatContent) {
            const {history, totle, lasted} = res.msg.firstChatContent
            let model = res.msg.firstChatContent.model
            this.commit('common/setHistoryLogin', history)
            this.commit('common/setHistoryLoginTotal', totle)
            commit('setModelAndSilde', true)
            state.chatList[state.chatKey][state.chatListCurrent].list = lasted
            this.commit('common/setSizeActiveLoad', lasted[0].size)
            if(model === 'sszdraw-v1-background-generation-v2'){
              model = 'sszdraw-v1'
            }
            if (model === 'mj') {
              if (lasted[0].size === '1:1') {
                this.commit('common/setSizeActive', '1024*1024')
                this.commit('common/setSizeActiveLoad', '1024*1024')
              }
              if (lasted[0].size === '16:9') {
                this.commit('common/setSizeActive', '1280*720')
                this.commit('common/setSizeActiveLoad', '1280*720')
              }
              if (lasted[0].size === '9:16') {
                this.commit('common/setSizeActive', '720*1280')
                this.commit('common/setSizeActiveLoad', '720*1280')
              }
            } else {
              this.commit('common/setSizeActive', lasted[0].size)
            }
            this.commit('common/setPicActive', lasted[0].style)
            this.commit('common/setPicNum', lasted[0].number)
            this.commit('common/setCurrentModel', lasted[0].model)
            if(lasted[0].model === 'sszdraw-v1-background-generation-v2'){
              this.commit('common/setCurrentModel', 'sszdraw-v1')
            }
            if (!rootGetters['common/sseErrors'] && lasted[0].model === 'mj') {
              dispatch('gethistorytypeandusedbutton', lasted[0].taskId)
            }
            if (rootGetters['common/sseErrors'] && !localStorage.getItem('chatValue')) {
              localStorage.setItem('chatValue', lasted[0].content)
            }
            if (rootGetters['common/modelCode'] === '' || ['gpt3.5', 'gpt4.0', 'yan'].includes(rootGetters['common/modelCode'])) {
              this.commit('common/setModelCode', model)
            }
            setTimeout(() => {
              commit('setModelAndSilde', false)
            }, 2000)
          } else {
            this.commit('common/setHistoryLogin', [])
            this.commit('common/setHistoryLoginTotal', 0)
            this.commit('common/setCurrentModel', '')
            if (val !== 'drawAdd') {
              this.commit('common/setModelCode', 'sszdraw-v1')
            }
          }
        }
      }
      if (status === 202) {
        if (res.code === 1) {
          ElMessage.warning('验证失败，请重新登录或完成注册')
          commit('setChatValue', '')
          commit('setChatList', [])
          clearStorage()
          setTimeout(() => {
            this.commit('common/setLoginMask', true)
          }, 3000)
        } else {
          ElMessage.warning(res.msg)
        }
      }
    })
  },
  // mj操作按钮
  gethistorytypeandusedbutton({commit, state}, taskId) {
    const data = {
      chatId: state.chatId,
      taskId: taskId
    }
    historyTypeandusedButton(data).then((response) => {
      const {data: res} = response
      if (res.code == 0) {
        // res.msg.buttons = res.msg.usedButton.map(item=>item.type)
        state.chatList[state.chatKey][state.chatListCurrent].list[0].button = res.msg
        commit('setScrollHeightFlag', true)
        setTimeout(() => {
          commit('setScrollHeightFlag', false)
        }, 3000)
        commit('setChatList', state.chatList)
      }
    })
  },
}

const mutations = {
  setChatValue(state, data) {
    state.chatValue = data
  },
  setChatList(state, data) {
    state.chatList = data
  },
  setChatId(state, data) {
    state.chatId = data
  },
  setChatListCurrent(state, data) {
    state.chatListCurrent = data
  },
  setChatObj(state, data) {
    state.chatObj = data
  },
  setChatKey(state, data) {
    state.chatKey = data
  },
  setReloadWeb(state, data) {
    state.reloadWeb = data
  },
  setReloadWebChat(state, data) {
    state.reloadWebChat = data
  },
  setModelAndSilde(state, data) {
    state.modelAndSilde = data
  },
  setButtonType(state, data) {
    state.buttonType = data
  },
  setScrollHeightFlag(state, data) {
    state.scrollHeightFlag = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
