/**
 * Created by Sunnie on 19/06/04.
 */

// charset="utf-8"
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validatePhoneNumber(str) {
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  return reg.test(str)
}

export function localStorageClear() {
  const openId = localStorage.getItem('openId')
  localStorage.clear()
  if (openId && openId != 'null') {
    localStorage.setItem('openId', openId)
  }
}

export function convertTimestampToDate(time) {
  const dateObj = new Date(Number(time) * 1000)
  const year = dateObj.getFullYear()
  const month = String(dateObj.getMonth() + 1).padStart(2, '0')
  const day = String(dateObj.getDate()).padStart(2, '0')
  const hours = String(dateObj.getHours()).padStart(2, '0')
  const minutes = String(dateObj.getMinutes()).padStart(2, '0')
  const seconds = String(dateObj.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export function calculateTimeRemaining(timeDifference) {
  const oneSecond = 1000
  const oneMinute = oneSecond * 60
  const oneHour = oneMinute * 60
  const oneDay = oneHour * 24

  const days = Math.floor(timeDifference / oneDay)
  const hours = Math.floor((timeDifference % oneDay) / oneHour)
  const minutes = Math.floor((timeDifference % oneHour) / oneMinute)
  const seconds = Math.floor((timeDifference % oneMinute) / oneSecond)
  return `${days}天${hours}小时${minutes}分钟${seconds}秒`
}

export function initTime() {
  const dateObj = new Date()
  const year = dateObj.getFullYear()
  const month = String(dateObj.getMonth() + 1).padStart(2, '0')
  const day = String(dateObj.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export function filteredChatGroups(chatList) {
  const allChats = []
  const filters = []
  for (const key in chatList) {
    if (chatList.hasOwnProperty.call(chatList, key)) {
      filters.push(key)
    }
  }
  for (const key of ['today', 'before7', 'before30']) {
    if (filters.includes(key)) {
      allChats.push({
        [key]: chatList[key]
      })
    }
  }

  for (const key of filters
    .filter(filter => filter.match(/^\d{2}$/))
    .sort()
    .reverse()) {
    allChats.push({
      [key]: chatList[key]
    })
  }
  // for (const key of filters
  //   .filter(filter => filter.match(/^\d{2}$/) || filter.match(/^\d{4}-\d{2}$/))
  //   .sort()
  //   .reverse()) {
  //   allChats.push({
  //     [key]: chatList[key]
  //   })
  // }
  for (const key of filters
    .filter(filter => filter.match(/^\d{4}-\d{2}$/))
    .sort()
    .reverse()) {
    allChats.push({
      [key]: chatList[key]
    })
  }
  return allChats
}


export function getIndexKey(chatList, id) {
  let index = 0, key = 'today'
  for (const key1 in chatList) {
    if (chatList.hasOwnProperty.call(chatList, key1)) {
      const element = chatList[key1]
      for (const key2 in element) {
        if (element.hasOwnProperty.call(element, key2)) {
          const item = element[key2]
          if (item.id === id) {
            key = key1
            index = key2 // 索引
          }
        }
      }
    }
  }
  return {index, key}
}


export function convertTo2DArray(arr, cols) {
  var result = [];
  var currentRow = [];
  for (var i = 0; i < arr.length; i++) {
    currentRow.push(arr[i]);
    if (currentRow.length === cols) {
      result.push(currentRow);
      currentRow = [];
    }
  }
  if (currentRow.length > 0) {
    result.push(currentRow);
  }
  return result;
}