<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>
<script>
import { ElConfigProvider } from 'element-plus'
// 引入中文包
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default {
  components: {
    [ElConfigProvider.name]: ElConfigProvider
  },
  setup() {
    const lang = () => {
      location.reload()
    }
    let locale = zhCn
    return {
      locale
    }
  }
}
</script>
<style></style>
