// 分类标签
import { reactive } from 'vue'
import { getcategory } from '@/api/expert'
import { ElMessage } from 'element-plus'
import { clearStorage } from '@/utils/common'
import { useStore } from 'vuex'
const store = useStore()
const state = reactive({
  labelList: [],
  labelId: '',
  labelSwitchval: true,
  labelSwitch: true
})

const getters = {
  labelLists(state) {
    return state.labelList
  },
  labelIds(state) {
    return state.labelId
  },
  labelSwitchs(state) {
    return state.labelSwitch
  },
  llabelSwitchvals(state) {
    return state.labelSwitchval
  },
}

const actions = {
  getLabelData({ dispatch, state, commit }) {
    getcategory().then(async response => {
      const { data: res, status } = response
      if (status == 200) {
        if (res.code == 0) {
          state.labelList = res.msg
          state.labelList.forEach(item => {
            item.active = false
            item.color = ''
            if (item.categoryName == '常用') {
              commit('setLabelId', item.id)
              item.active = true
            }
          })
          this.dispatch('swiper/getSwiperLists')
          dispatch('generateRandomColors')
        }
      }
      if (status == 202) {
        if (res.code == 1) {
          ElMessage.warning('验证失败，请重新登录或完成注册')
          clearStorage()
          setTimeout(() => {
            store.commit('common/setLoginMask', true)
          }, 3000)
        } else {
          ElMessage.warning(res.msg)
        }
      }
    })
  },
  generateRandomColors({ commit, state }) {
    for (let i = 0; i < state.labelList.length; i++) {
      const randomColor =
        '#' + Math.floor(Math.random() * 16777215).toString(16)
      state.labelList[i].color = randomColor
    }
    commit('setLabelList', state.labelList)
  }
}

const mutations = {
  setLabelList(state, data) {
    state.labelList = data
  },
  setLabelId(state, data) {
    state.labelId = data
  },
  setLabelSwitch(state, data) {
    state.labelSwitch = data
  },
  setLabelSwitchval(state, data) {
    state.labelSwitchval = data
  }
  
}

export default {
  state,
  getters,
  actions,
  mutations
}
