// 提示词轮播图
import {reactive} from 'vue'
import {getpromptsbycategory} from '@/api/expert'
import {ElMessage} from 'element-plus'
import {clearStorage} from '@/utils/common'
import {useStore} from 'vuex'
import {convertTo2DArray} from "@/utils/validate";

const store = useStore()

const state = reactive({
  // 提示词数据
  swiperLists: [],
  swiperBrs: 0
})

const getters = {
  swiperListsData: (state, rootState, rootGetters) => {
    state.swiperLists.forEach(swiper => {
      swiper.forEach((item) => {
        item.labels.forEach(el => {
          rootGetters.label.labelList.forEach(item1 => {
            if (el.name == item1.categoryName) {
              el.color = item1.color
            }
          })
        })
      })
    })
    return state.swiperLists
  }
}

const actions = {
  getSwiperLists({commit, state, rootState, rootGetters}) {
    getpromptsbycategory({
      labelswitch: rootGetters['label/labelSwitchs'] ? 'or' : 'and',
      id: rootGetters['label/labelIds']
    }).then(response => {
      const {data: res, status} = response
      if (status === 200) {
        if (res.code === 0) {
          const list = res.msg
          list.forEach(item => {
            item.enShow = false
            item.labels = []
            item.dialogueShow = false
            item.category_name.forEach(el => {
              item.labels.push({
                name: el,
                color: ''
              })
            })
          })
          const swiperArr = convertTo2DArray(list, 3)
          commit('setSwiperLists', swiperArr)
        }
      }
      if (status === 202) {
        if (res.code === 1) {
          ElMessage.warning('验证失败，请重新登录或完成注册')
          clearStorage()
          setTimeout(() => {
            store.commit('common/setLoginMask', true)
          }, 3000)
        } else {
          ElMessage.warning(res.msg)
        }
      }
    })
  }
}

const mutations = {
  setSwiperLists(state, data) {
    state.swiperLists = data
  },
  setSwiperBrs(state, data) {
    state.swiperBrs = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
