import axios from 'axios'
import { getCanvasTouch } from '@/utils/canvasTouch'
const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: true,
  timeout: 1000 * 125 // request timeout
})
// 发起请求之前的拦截器
service.interceptors.request.use(
  config => {
    if (config.url.indexOf('/login/gettoken') != -1) {
      config.headers = {
        'content-type': 'application/json'
      }
    } else {
      config.headers = {
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
    if (localStorage.getItem('token')) {
      config.headers['token'] = 'bearer ' + localStorage.getItem('token')
    } else if (localStorage.getItem('webTouch')) {
      config.headers['Authorization'] =
        localStorage.getItem('webTouch') + '+' + getCanvasTouch()
    }
    if (
      config.url.indexOf('chat/syncchat') != -1 ||
      config.url.indexOf('login/verifyloginmsg') != -1 ||
      config.url.indexOf('login/gettoken') != -1
    ) {
      config.headers['Authorization'] =
        localStorage.getItem('webTouch') + '+' + getCanvasTouch()
    }
    return config
  },
  error => Promise.reject(error)
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
